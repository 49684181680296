@import '~antd/dist/antd.less';
// See ant theme variables here:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// -------- regular ant colors (some replaced by spark) --------
// @primary-color: @blue-6;
// @info-color: @primary-color;
// @success-color: @green-6;
// @processing-color: @blue-6;
// @error-color: @red-5;
// @highlight-color: @red-5;
// @warning-color: @gold-6;
// @normal-color: #d9d9d9;
// @white: #fff;
// @black: #000;

// -------- Mabel Brand Colors --------
@mabel-purple: #870051;
@mabel-dark-blue: #00526f;
@mabel-green: #006b77;

@mabel-orange: #f05655;
@mabel-blue: #6886c5;
@mabel-light-green: #1dd3bd;

@mabel-yellow: #ffb87d;
@mabel-light-blue: #a6dcef;
@mabel-light-grey: #dddde1;

@mabel-dark-burgandy: #5b1e35;
@mabel-dark-purple: #3a2c4d;
@mabel-dark-grey: #425563;

// Ant customizations
@primary-color: @mabel-purple;
//@success-color: shade(@mabel-light-green, 25%);
@warning-color: @mabel-yellow;
@error-color: @mabel-orange;

//@font-family: 'Poppins', sans-serif;

// Ant menu customization
@menu-bg: @white;
@menu-highlight-color: @primary-color;
//@menu-item-font-size: @font-size-lg;
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: white;
}

// Ant page customization
@layout-body-background: @mabel-light-blue;

// Ant link customization
@link-color: @blue-6;

// Give more margin when form items have "help" text
.ant-form-item-explain {
  margin-bottom: 16px;
}

// Utility classnames
.success-color {
  color: @success-color;
}

.warning-color {
  color: @warning-color;
}
.danger-color {
  color: @error-color;
}

.text-color-secondary-dark {
  color: @text-color-secondary-dark;
}
.text-color-secondary-dark:hover {
  color: white;
}
.text-color-secondary-dark:focus {
  color: white;
}

.card-shadow {
  box-shadow: @card-shadow;
}

.home-card {
  box-shadow: @card-shadow;
  border-radius: 8px;
  width: 200px;
}
.home-card:hover {
  border-color: @primary-color;
}

.select-wrap-options .ant-select-item-option-content {
  white-space: normal;
  overflow: auto;
}

// Header customization
.header-left {
  float: left;
  height: 64px;
  width: 150px;
  padding: 8px 24px;
  margin-left: 4px;
}

// Amplify UI and Login customization
:root {
  --amplify-primary-color: @primary-color;
  --amplify-primary-tint: @primary-5;
  --amplify-primary-shade: @primary-7;
  //--amplify-secondary-color: @mabel-dark-purple;
  //--amplify-secondary-tint: color(~`colorPalette('@{mabel-blue}', 5) `);
  //--amplify-secondary-shade: color(~`colorPalette('@{mabel-blue}', 7) `);
}
amplify-authenticator {
  --container-height: 0;
}

// Color Picker customization inside ant popup
.color-picker-popup .ant-popover-inner-content {
  padding: 0px;
  margin: 0px;
}
.color-picker-popup {
  input {
    width: 100% !important;
  }
}

.layout-page-background {
  background: @body-background;
}

// .ant-carousel .slick-list .slick-slide {
//   pointer-events: auto;
// }

// .invert-colors .ant-divider-horizontal.ant-divider-with-text-center::before,
// .ant-divider-horizontal.ant-divider-with-text-center::after {
//   border-top: 1px solid white;
// }

// .invert-colors .ant-tooltip-inner {
//   width: 320px;
// }

.fields-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.fields-collapse > .ant-collapse-item > .ant-collapse-header {
  margin-left: 10%;
}

.sentry-error-embed-wrapper {
  z-index: 1001 !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: unset;
  height: unset;
  min-width: 104px;
}

.ant-timeline-item-tail {
  border-color: @mabel-dark-grey;
}

.wavy-error {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: @error-color;
}

.wavy-warning {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: @warning-color;
}

.sms-receive {
  background: #e5e5ea;
  color: black;
  max-width: 300px;
  word-wrap: break-word;
  margin-bottom: 12px;
  position: relative;
  padding: 10px 20px;
  border-radius: 25px;
  white-space: pre-line;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 25px;
    left: -7px;
    width: 20px;
    background-color: #e5e5ea;
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 25px;
    left: -26px;
    width: 26px;
    background-color: #fbfbfb;
    border-bottom-right-radius: 10px;
  }
}

